<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import treeRoleModule from './treeRoleModulee'
import { getAction, putAction } from '@/command/netTool'
import { message } from 'ant-design-vue'

export default {
  name: 'bodyMain',
  data() {
    return {
      ...api.command.getState(),
      subordinate: []
    }
  },
  mounted() {
    api.command.getList.call(this, {
      // url: '/account/sys/page',
      url: '/account/sys/page',
      current: 1
    })

    getAction('/api/app/list').then(result => {
      this.subordinate = result.data.map(e => {
        return {
          ...e,
          text: e.appName,
          value: e.appCode
        }
      })
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '昵称',
          type: 'input',
          key: 'nickName'
        },
        {
          name: '手机号码',
          type: 'input',
          key: 'phone'
        },
        {
          name: '用户名',
          type: 'input',
          key: 'username'
        },
        {
          name: '真实姓名',
          type: 'input',
          key: 'realName'
        }
      ]
    },
    onReset(records) {
      putAction(`/admin/account/sys/restPwd?id=${records.userId}`, {}, '/bpi').then(() => {
        message.success('密码重置成功')
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'userId',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.userId - b.userId
        },
        {
          dataIndex: 'avatar',
          title: '头像',
          type: 'image',
          isId: true,
          width: 60,
          align: 'left'
        },
        {
          dataIndex: 'nickName',
          title: '昵称',
          type: 'lt-100',
          sorter: (a, b) => a.nickName.length - b.nickName.length
        },
        {
          dataIndex: 'password',
          title: '密码',
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.password.length - b.password.length
        },
        {
          dataIndex: 'username',
          title: '用户名',
          type: 'lt-100',
          sorter: (a, b) => a.username.length - b.username.length
        },
        {
          dataIndex: 'realName',
          title: '真实姓名',
          type: 'lt-100',
          sorter: (a, b) => a.realName.length - b.realName.length
        },
        {
          dataIndex: 'gender',
          title: '性别',
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.gender.length - b.gender.length
        },
        {
          dataIndex: 'phone',
          title: '手机号码',
          type: 'lt-100',
          sorter: (a, b) => a.phone - b.phone
        },
        {
          dataIndex: 'email',
          title: '邮箱',
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.email.length - b.email.length
        },
        {
          dataIndex: 'pwdSecurityLevel',
          title: '密码安全级别',
          type: 'lt-100',
          isId: true
        },
        {
          dataIndex: 'pwdUpdateDate',
          title: '密码最后更新时间',
          type: 'lt-100',
          isId: true,
          sorter: (a, b) => moment(a.pwdUpdateDate).unix() - moment(b.pwdUpdateDate).unix()
        },
        {
          dataIndex: 'createTime',
          isId: true,
          title: '创建时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix()
        },
        {
          dataIndex: 'updateTime',
          isId: true,
          title: '修改时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },
        {
          dataIndex: 'miniOpenid',
          isId: true,
          title: '微信小程序openid',
          type: 'lt-100'
        },
        {
          dataIndex: 'tenantId',
          isId: true,
          title: '所属租户id',
          type: 'lt-100'
        },
        {
          dataIndex: 'wxOpenid',
          isId: true,
          title: '微信openid',
          type: 'lt-100'
        },
        {
          dataIndex: 'ymId',
          isId: true,
          title: '友盟ID',
          type: 'lt-100',
          sorter: (a, b) => a.ymId - b.ymId
        },
        {
          dataIndex: 'remark',
          isId: true,
          title: '备注',
          type: 'lt-100'
        },
        {
          dataIndex: 'lockFlag',
          title: '状态',
          width: '60px',
          type: 'badge',
          onFilter: (value, record) => record.lockFlag == value,
          render(data) {
            return {
              showDot: true,
              name: data == 9 ? '失效' : '正常',
              color: data == 9 ? 'red' : 'green'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => this.$router.push('/rights/user/detailAdd?id=' + records.userId)
              },
              {
                name: '重置密码',
                onClick: () => this.onReset(records)
              },
              {
                name: '添加角色',
                onClick: () => {
                  this.onRowSelectRole(`${records.userId}`)
                }
              },
              {
                name: records.lockFlag == 9 ? '正常' : '失效',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/account/sys/${records.lockFlag == 9 ? 'enable' : 'disable'}?id=${records.userId}`
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/account/sys/${records.userId}`
                  })
              }
            ]
          }
        }
      ]
    },
    handleShowDraw(e) {
      apiTool.showDrawer({
        title: '分配角色权限',
        width: '1000px',
        view: treeRoleModule,
        viewProps: {
          roleData: this.subordinate,
          userId: e
        },
        success: ({ data, setHidden }) => {
          let obj = {
            roles: data.map(e => e.roleCode).toString(),
            userId: e
          }

          api.command.edit
            .call(this, {
              url: '/account/sys/authRole',
              params: obj,
              isPost: false
            })
            .then(result => {
              setHidden()
            })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    onRowSelectRole(e) {
      this.handleShowDraw(e)
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/rights/user/detailAdd')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
